.content-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #EDEDED;
    box-sizing: border-box;
}

.content-left {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-body {
    width: 70%;
    box-sizing: border-box;
}

.content-right {
    width: 15%;
}

.vertical-line {
    border:         none;
    border-left:    1px solid hsla(200, 10%, 50%,100);
    height:         100vh;
    width:          1px;     
    z-index: 4;
}

@media only screen and (max-width: 1200px) {
    .content-left,.content-right {
        display: none;
    }

    .content-body {
        width: 100%;
    }
}