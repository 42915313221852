.bento-container {
    display: grid;
    width: 100%;
    grid-gap: 25px;
    grid-auto-columns: repeat(3fr, 1fr);
    grid-auto-rows: minmax(150px, auto);
    box-sizing: border-box;
    padding-bottom: 40px;
}

.bento-box {
    border-radius: 15px;
    background: whitesmoke;
    padding: 20px;
    cursor: pointer;
    transition: transform 0.2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.bento-box:hover {
    transform: scale(1.02);
}

@media only screen and (max-width: 1200px) {
    .bento-container {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media only screen and (max-width: 800px) {
    .bento-container {
        padding-left: 15px;
        padding-right: 15px;
        grid-auto-columns: auto;
        grid-gap: 18px;
    }
}