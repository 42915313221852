.header-content {
    display: flex;
    justify-content: flex-end;
    height: 135px;
    padding-bottom: 20px;
    padding-top: 20px;
    align-items: center;
    box-sizing: border-box;
    gap: 65px;
    z-index: 100;
    position: relative;
}

.header-tag, .hamburger-header-tag {
    border: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
    cursor: pointer;
}

.header-button, .hamburger-header-button {
    border-radius: 16px;
    background: #007aff26;
    border: none;
    width: 140px;
    height: 60px;
    flex-shrink: 0;
    color: #007AFF;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: transform 0.2s;
    cursor: pointer;
}

.header-button:hover {
    transform: scale(1.1);
}

.current-page {
    text-decoration: underline #007AFF;
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
}

.hamburger-icon {
    display: none;
    width: 30px;
    height: 22px;
}

.hamburger-bar-1, .hamburger-bar-2, .hamburger-bar-3 {
    width: 100%;
    height: 2px;
    background-color: black;
    display: block;
}

.hamburger-menu {
    position: absolute;
    background-color: whitesmoke;
    top: 80px;
    right: 0px;
    height: 100%;
    z-index: 900;
    width: 100%;
    max-width: 600px;
    display: none;
    flex-direction: column;
    padding-top: 100px;
    gap: 10%;
}

@media only screen and (max-width: 1200px) {
    .header-content {
        justify-content: center;
    }
  }

@media only screen and (max-width: 800px) {
    .hamburger-menu {
        display: flex;
    }
    .header-content {
        justify-content: flex-end;
        padding-right: 30px;
        height: 80px;
    }
    .header-tag {
        display: none;
    }
    .hamburger-icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 5px;
    }

    .header-button {
        display: none;
    }
}